





















import Vue from "vue";
import { dataProjects } from "@/models/Types/Post/DataProjects";
import { Post } from "@/models/Types/Post/Post";

export default Vue.extend({
  async mounted() {
    this.projects = await dataProjects;
  },
  data() {
    const projects: Post[] = [];
    return { projects };
  }
});
